import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Cloudinary } from '@cloudinary/url-gen';
import { auto } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";



import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { SwiperNavButtons } from "./SwiperNavButton";

SwiperCore.use([Navigation]);

const team = [
  {
    id: 1,
    name: "Fraser A. Edkins",
    position: "Senior Partner",
    imageId: "Fraser_abpsgh", 
    PracticeHead: "Family and Matrimonial Law",
    YOR: "1980",
    Email: "fae@cwg.co.zw ",
    Qualifications: "BL (Hons) LLB (Rhodesia), Conveyancer and Notary Public ",
    Status:
      "Admitted Legal Practitioner, Notary Public and Conveyancer in Zimbabwe",
    MainPracticeAreas: "Family and Matrimonial Law. ",
    description: `Fraser  has been the firm's senior partner and chairman since  1st  of January 2016. In addition to assuming his new role, Fraser heads the firm’s family and matrimonial law practice  group specializing in matrimonial litigation. Fraser is a trustee of Childline and the chairman of the Zimbabwe History Society. Before joining the firm, Fraser was a Magistrate in the Civil and Magistrates Court for several years. Fraser has a wealth of experience in civil and criminal litigation and is considered as one of the country’s leading and experienced litigation attorneys.`,
  },
  {
    id: 2,
    name: "Thembiwe C. Mazingi",
    position: "Managing Partner",
    PracticeHead: "Conveyancing and Intellectual Property Law",
    imageId: "Mazingi_uozaby",
    YOR: "1982",
    Email:"mazingit@cwg.co.zw" ,
    Qualifications:
      "BL (Hons) LLB (Zim), MBA (UZ) Certificate in Advanced Taxation & VAT (UNISA); Certificate in Advanced Corporate & Securities Law (UNISA), Conveyancer and Notary Public.",
    Status:
      "Admitted Legal Practitioner, Notary Public & Conveyancer in Zimbabwe",
    MainPracticeAreas:
      "Conveyancing, Notarial Practice, Intellectual Property, Securities, Tax Law, Trusts, Commercial and Corporate Law, Energy law , Corporate Governance,  Compliance and Regulatory .",
    description: `Thembiwe leads the firm’s  Conveyancing and Intellectual Property practice groups   and has many years of experience in conveyancing and property law. Thembiwe sits on various boards including Ariston Holdings Limited, African Century Limited and AXIA Corporation Limited. Thembiwe’s past directorships include NICOZ Diamond Insurance, First Mutual Life Assurance, Zimbabwe Allied Banking Group Limited, Fidelity Asset Management, Zimbabwe Electricity Supply Authority and National Tyre Services. Thembiwe is a member of the International Bar Association in which she is a member of the African Regional, Mergers and Acquisitions and Practice Management Forums and Committees.`,
  },
  {
    id: 3,
    name: "Andrew P. Lane-Mitchell",
    position: "Partner",
    imageId: "Andrew_x0ojwz",
    YOR: "1986",
    PracticeHead:"",
    Email:	"alm@cwg.co.zw", 
    Qualifications:
      "BA(CAPE), LLB (CAPE) Certificate of eligibility under the Qualified Lawyers Transfer Regulations, United Kingdom.",
    Status: "",
    MainPracticeAreas:
      "Corporate and Commercial Law, including IPOs, Project Finance, Mergers & Acquisitions, Corporate Restructuring, Joint Foreign Exchange, and Banking.",
    description: `Andrew  heads the firm’s Corporate and Commercial practice group having practiced in many years in the corporate sector. Andrew’s experience includes being the Group Legal Counsel for Meikles Limited (listed on the Zimbabwe and London Stock Exchanges) and Corporate Finance Manager for Stanbic Zimbabwe Limited, one of Zimbabwe’s leading retail banks and a division of Standard Bank South Africa, which positions he held before joining the firm’s corporate law team.`,
  },
  {
    id: 4,
    name: "Nokuthula Moyo",
    position: "Partner",
    imageId: "Moyo_bf3mwa",
    YOR: "1994",
    Email: "moyon@cwg.co.zw",
    PracticeHead: "Labour & Employment Law  and Dispute Resolution",
    Qualifications: "LLBS (Hons) (Zim), Conveyancer and Notary Public",
    Status:
      "Admitted Legal Practitioner, Notary Public and Conveyancer in Zimbabwe",
    MainPracticeAreas:
      "Labour and Employment  Law, Civil Litigation,  Alternative Dispute Resolution, Insolvency , Tax  Law, Human Rights Law , and Media Law.",
    description: `Nokuthula  is one of Zimbabwe’s leading labour and employment experts  and heads the firm’s Labour and Employment Law and  Dispute Resolution practice groups . She is also a human rights activist and is the past chairperson of the Zimbabwe Lawyers for Human Rights. Nokuthula has advises various entities in matters relating to their organizational restructuring and succession planning issues, employee share ownership schemes, drafting and reviewing employment codes of conduct, retrenchment negotiations and labor law litigation before National Employment Committees, the Ministry of Labour, Labour Court, and the Supreme Court.`,
  },

  {
    id: 5,
    name: "David C. Dhumbura",
    position: "Partner",
    imageId: "David_qhyjli",
    YOR: "2003",
    PracticeHead: "Criminal Litigation",
    Email:"dhumbura@cwg.co.zw",

    Qualifications: "LLBS (Hons) (Zim), Conveyancer and Notary Public ",
    Status: "",
    MainPracticeAreas:
      "Criminal Law, Labour Law, Human Rights Law and Media Law.",
    description: `David  leads the firm’s criminal law department and has a wealth of knowledge and experience in criminal law and procedure having served as a magistrate for many years before joining private practice.`,
  },
  {
    id: 6,
    name: "Wellington Magaya",
    position: "Partner",
    PracticeHead: "Labour & Employment Law Practice",
    imageId: "Wellington_fohjnq",
    Email: "magaya@cwg.co.zw",
    YOR: "2008",
    Qualifications: "LLBS (Hons) (Zim), MBA (Zim)",
    Status:
      "Admitted as a Legal Practitioner, Notary Public, and Conveyancer in Zimbabwe",
    MainPracticeAreas:
      "Corporate and Commercial Law, Mining and Energy Law ,  Labour Law, Alternative Dispute Resolution ,  Civil Litigation and Compliance Law.",
    description: `Wellington joined the firm as an associate in 2008 and was elevated to partnership in 2015 . He mainly practices in the corporate and Commercial Law, Labour and Employment Law and Dispute Resolution practice groups. He  has a wealth of experience in  negotiating commercial transactions, drafting of commercial agreements, litigation,  advising on energy and mining projects. Wellington advises many major corporations in Zimbabwe and abroad in all aspects of the law. In 2013 Wellington was appointed by the Law Society of Zimbabwe as a member of the team mandated to draft the Legal Practitioners’ Code of Conduct. Wellington sits on the Council of the Law Society of Zimbabwe. Wellington has also attended trainings in the continent and abroad focusing on project financing, financing for the extractive industry and aviation conducted by the Loan Market Association and GECAS. He is the past President of the Law Society of Zimbabwe.`,
  },
  {
    id: 7,
    name: "Nyadzisai M. Chikwene",
    position: "Partner",
    imageId: "Matlida_k6mbla",
    Email:"nyadzi@cwg.co.zw",
    PracticeHead:"",
    YOR: "2016",
    Qualifications: "LLB (UNISA) , Conveyancer and Notary Public ",
    Status: "",
    MainPracticeAreas:
      "Corporate and Commercial Law, Mining and Energy Law ,  Labour Law, Alternative Dispute Resolution ,  Civil Litigation and Compliance Law.",
    description: `Nyadzi was elevated to partnership in January 2021 and mainly practices in the Conveyancing and Intellectual Property practice groups. She  specialises in intellectual property registration (locally, regionally and internationally),advisory,assignment  and cession of intellectual property, management and maintenance of intellectual property portfolios on behalf of clients. She provides advice on initial registration and renewal of IP rights both locally and internationally; purchase, transfer and licensing of IP rights; IP portfolio management; enforcement of IP rights; due diligence investigations; licensing; confidential information protection; restraints of trade; sponsorship agreements; product design protection; commercial agreements relating to IP and franchising. Nyadzi has also participated in the formulation of laws and policies on intellectual property in Zimbabwe and ARIPO. She has successfully represented both local individuals and large  foreign corporations in the registration and prosecution of their trademarks and patents both at the local Registry up to the High Courts. She has been involved in registration of securities for large financing transactions as well as registration of transfers from sales, donations and estates. Nyadzi also advises on formation and registration of charitable or family Trusts together with all Notarial duties.`,
  },
  {
    id: 8,
    name: "DANIEL T.M.  MATAWU",
    position: "Partner",
    imageId: "Daniel_k6ibmz",
    YOR: "2017",
    PracticeHead:"",
    Email: "dmatawu@cwg.co.zw" ,
    Qualifications: "LLB (UL) LLM in Commercial Law (UCT) ",
    Status: "Admitted Legal Practitioner",
    MainPracticeAreas:
      "Corporate and Commercial Law, Commercial Litigation, Labour Law and Compliance Law.",
    description: `Daniel joined the firm as an associate in April 2016 and was elevated to partnership in January 2022. During his tenure with the firm Daniel has developed and has been exposed to various areas of the law which have seen him specialize in corporate and commercial law. 

                  Besides his vast experience in dealing with commercial litigation Daniel has also has the experience of having worked in house for a  multi-national company where he mainly focused on compliance related issues and advisory.

                  As a passion project, Daniel in his spare time attends seminars and events hosted by an entity that focuses on Sports related issues, where he has on numerous occasions presented on sports law related issues and ethics in sport.`,
  },
  {
    id: 11,
    name: "ENNOCENT T. CHIDAWANYIKA",
    position: "Partner",
    imageId: "et_xbryja",
    YOR: "2021",
    PracticeHead:"",
    Email:	"ec@cwg.co.zw" ,
    Qualifications: `
   LLB Cum laude (UNISA); 
   BSc (hons) Econ;
  MSc Econ (UZ); 
  MBA (Banking &Finance) (NUST) AIOB (ZIM); 
  CAIB (SA) LLM (Law of Mineral and Petroleum Extract and Use) UCT; 
    PhD (Mining Law) Candidate, Conveyancer and Notary Public 
`,
    Status:
      "Admitted Legal Practitioner, Notary Public, and Conveyancer in Zimbabwe",
    MainPracticeAreas: "Banking, Corporate, Company and Compliance services and is well versed in Mining Law, Commercial/Corporate Law (Mergers, Acquisitions, Banking, Taxation, Policy Formulation, Medical Law, and Insolvency Law.",
    description: `Ennocent joined the firm as an associate in November 2019  and mainly practices in the  Corporate and Commercial Law practice group. He is a former senior banker and economist who worked for both local and international banks. He is admitted to practice as a Legal Practitioner, Notary Public, and Conveyancer in Zimbabwe. He was inducted into the Golden Key International Academic Excellency Society. He is a member of the International Bar Association, with committee membership in Mining Law, Oil and Gas Law, Banking Law, Corporate and Mergers & Acquisitions.`,
  },

  {
    id: 12,
    name: "Lisa B. Zvinavashe",
    position: "Associate",
    imageId: "lisa_o2qdv9",
    PracticeHead:"",
    YOR: "2022",
    Email:	"lzvinavashe@cwg.co.zw", 
    Qualifications: "LLB (UNISA)",
    Status: "Admitted Legal Practitioner in Zimbabwe",
    MainPracticeAreas:
      "Corporate and Commercial Law",
    description: `Lisa joined the firm as an associate in June 2022 and mainly practices in the Corporate and Commercial practice group. `,
  },
  {
    id: 13,
    name: "Samantha Mbauya",
    position: "Associate",
    imageId: "Samantha_xikbed",
    PracticeHead:"",
    YOR: "2022",
    Email:	"smbauya@cwg.co.zw", 
    Qualifications: "LLB (UZ), Conveyancer and Notary Public",
    Status:
      "",
    MainPracticeAreas: "Criminal Law,Civil Litigation, Alternative Dispute Resolution, Family Law.",
    description: `Samantha joined the firm as an associate in June 2022 and mainly practices in the Dispute Resolution Criminal Law, and Family Law practice groups. `,
  },
  {
    id: 14,
    name: "William F.M. Diarra",
    position: "Associate",
    imageId: "dia_nkp1qi",
    YOR: "2023",
    PracticeHead:"",
    Status: "",
    Qualifications: "LLB (UCT)",
    Email:	"wdiarra@cwg.co.zw ",
    MainPracticeAreas:
      "Corporate and Commercial Law , Mining Law, Civil Litigation, Labour Law, Alternative Dispute Resolution,   Insolvency, Banking and Finance Law, Exchange Control Law, Competition Law, Tax Law, and Intellectual Property ",
    description: `
    William joined the  firm as an associate  in June 2021 and mainly practices in the Corporate and Commercial, Dispute Resolution,  and Labour and Employment practice groups . During his time with the firm William has handled a wide range of transactions including: 
•	advising a foreign investor on its indirect acquisition of a 51% controlling interest in  lithium mining company with a  transaction value of  US$77m .Conducting due diligence for the same investor in support of its GDR Listing and Hong Kong Stock Exchange Listing. 
•	 advising a foreign debt facility fund  on the disbursement  of a US$13m secured term loan facility to a foreign sponsor  in respect of several solar power projects  in Zimbabwe with an expected cumulative output of 20MW, 
•	 advising a foreign investor on its acquisition of a group of mining companies in Zimbabwe with a transaction value of US$52m, 
•	advising  a foreign investor on its establishment of a granite quarrying business in Zimbabwe with an investment value of US$2m , 

    `,
  },
  {
    id: 15,
    name: "Mduduzi A. Ruwitah",
    position: "Associate",
    imageId: "Mduduzi_aibwzv",
    PracticeHead:"",
    Email:" mdu@cwg.co.zw" ,
    YOR: "2023",
    Qualifications: ": LLB, LLM (International Law) , LLM (Dispute Resolution) (UCT) ",
    Status: "Admitted Legal Practitioner in Zimbabwe",
    MainPracticeAreas:
      "Civil Litigation, Alternative Dispute Resolution, Corporate and Commercial Law",
    description: `
    Mduduzi joined the firm as an associate in June 2023 and mainly practices in the Dispute Resolution  practice group. With a strong foundation in international law and dispute resolution, Mduduzi brings a wealth of knowledge and expertise to our team.

Mduduzi holds an LLM degree in International Law and another in Dispute Resolution, both from the University of Cape Town. His academic pursuits have equipped him with a deep understanding of complex legal issues and the skills necessary to navigate various dispute resolution processes.
Mduduzi's practice focuses on litigation, negotiation, arbitration, and mediation. He is committed to providing exceptional legal services to our clients and delivering tailored solutions to their unique needs

    
    `,
  },
  {
    id: 16,
    name: "Nyasha Gift Nyatsambo",
    position: "Associate",
    imageId: "gift_x4m96n",
    PracticeHead:"",
    Email:"Gn@cwg.co.zw",
    YOR: "2024",
    Qualifications: "LLM LLB (UCT)",
    Status: "Registered Legal Practitioner in Zimbabwe",
    MainPracticeAreas:
      " Civil Litigation, Labour and Employment Law, Corporations Law, Mining Law, Tax Law",
    description: `
   Nyasha joined the Litigation Department of the firm in 2022 as a legal intern. He gained exposure whilst working under the tutelage of the Partners and was recently elevated to the role of Associate. He enjoys litigation and has a keen interest in tax law, corporations law, mergers & acquisitions, and family law.
   `,
  },

];

function CarouselTeams() {
  const cld = new Cloudinary({ cloud: { cloudName: 'ddza3qui6' } });

  const [open, setOpen] = useState(false);

  const breakpoints = {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
  };

  return (
    <div className="">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        breakpoints={breakpoints}
        autoplay={{
          delay: 3000,
        }}
        
      >
         {team.map((member) => {
          const img = cld
            .image(member.imageId)
            .format("auto")
            .quality("auto")
            .resize(auto().gravity(autoGravity()).width(500).height(500));

          return (
            <SwiperSlide
              key={member.id}
              className="flex max-w-xl px-16 flex-col items-start justify-between"
            >
              <div className="slide-content">
                <div className="h-48 w-48 md:h-72 md:w-72 overflow-hidden rounded-md group-hover:opacity-75">
                  <img
                    src={img.toURL()}
                    alt={member.name}
                    loading="priority"
                    onClick={() => setOpen(member.id)}
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                  />
                </div>
                <div className="mt-4 flex md:gap-x-24 gap-x-24">
                  <h3 className="text-sm text-gray-700">{member.name}</h3>
                  <p className="text-sm text-gray-500">{member.position}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}

        {team.map((member, id) => (
          <Transition.Root
            key={member.id}
            show={open === member.id}
            as={Fragment}
          >
            <Dialog
              key={id}
              as="div"
              className="relative z-10"
              onClose={setOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-in-out duration-500"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in-out duration-500"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                            <button
                              type="button"
                              className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </Transition.Child>
                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                          <div className="px-4 sm:px-6">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900"></Dialog.Title>
                          </div>
                          <div className="relative mt-6 flex-1 px-4 sm:px-6">
                            <div className="bg-white">
                              <div className="pt-6">
                                {/* Image gallery */}
                                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl ">
                                  <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
                                    <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
                                    <img
                                src={cld
                                  .image(member.imageId)
                                  .resize(auto().gravity(autoGravity()))
                                  .toURL()}
                                alt={member.name}
                                loading="priority"
                                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                              />
                                    </div>
                                  </div>
                                </div>

                                {/* Product info */}
                                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:max-w-7xl lg:pb-24 lg:pt-16">
                                  <div className=" lg:pr-8">
                                    <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                                      {member.name}{" "}
                                    </h1>
                                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                                      {member.position}
                                    </h1>
                                  </div>

                                  <div className="py-10 lg:col-start-1 lg:pb-16 lg:pr-4 lg:pt-6">
                                    {/* Description and details */}
                                    <div>
                                      <h3 className="sr-only">Description</h3>
                                      <div className="space-y-6">
                                        {member.description
                                          .split("\n")
                                          .map((paragraph, index) => (
                                            <p
                                              className="text-base text-gray-900"
                                              key={index}
                                            >
                                              {paragraph.trim()}
                                            </p>
                                          ))}
                                      </div>
                                    </div>

                                    {member.YOR ||
                                    member.Qualifications ||
                                    member.PracticeHead ||
                                    member.MainPracticeAreas ? (
                                      <div className="mt-10">
                                        <h3 className="text-sm font-medium text-gray-900">
                                          Highlights
                                        </h3>
                                        <div className="mt-4">
                                          <ul
                                            className="list-disc space-y-2 pl-4 text-sm"
                                          >
                                            {member.YOR && (
                                              <li className="text-gray-400">
                                                <span className="text-gray-600">
                                                  Year of Registration:{" "}
                                                  {member.YOR}
                                                </span>
                                              </li>
                                            )}
                                            {member.Qualifications && (
                                              <li className="text-gray-400">
                                                <span className="text-gray-600">
                                                 <b>Qualifications:</b> {" "}
                                                  {member.Qualifications}
                                                </span>
                                              </li>
                                            )}
                                            {member.PracticeHead && (
                                              <li className="text-gray-400">
                                                <span className="text-gray-600">
                                                 <b>Practice Head:</b>  {member.PracticeHead}
                                                </span>
                                              </li>
                                            )}
                                            {member.MainPracticeAreas && (
                                              <li className="text-gray-400">
                                                <span className="text-gray-600">
                                                  <b>Main Practice Areas:</b>{" "}
                                                  {member.MainPracticeAreas}
                                                </span>
                                              </li>
                                            )}
                                          </ul>
                                        </div>

                                        {member.Email && (
                                        
                                        <a href={`mailto:${member.Email}`} className="text-gray-400 pt-12">
                                                <span className="text-gray-600 underline underline-offset-2">
                                                  {member.Email}
                                                </span>
                                              </a>
                                              )}


                                      </div>
                                    ) : null}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        ))}
        <div className="ml-14 mt-4">

        <SwiperNavButtons/>
        </div>
      </Swiper>
    </div>
  );
}

export default CarouselTeams;
