import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';

export default function NotificationBell() {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      {/* Notification Bell */}
     

      <div className="p-10 min-h-screen flex items-center justify-center ">
  <button className="relative"         aria-label="Open Notifications"         onClick={openModal}  >
    <div className="top-4 right-4  bg-gray-50 p-2 text-gray-700 hover:bg-gray-300 rounded-full shadow-2xl">
    <BellIcon className="h-6 w-6" aria-hidden="true" />

    </div>
    <div className="absolute top-0 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-red-700 animate-ping"></div>
    <div className="absolute top-0 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-red-700"></div>
  </button>
</div>

      {/* Modal */}
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {/* Header */}
                  <div className="bg-black text-white p-4 rounded-t-lg">
                    <h2 className="text-xl font-bold">CWG LEGAL INSIGHTS</h2>
                  </div>

                  {/* Content */}
                  <div className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-4">
                      ARE YOU COMPLIANT WITH DATA PROTECTION REGULATIONS?
                    </h3>
                    <p className="text-sm text-gray-700 mb-4">
                      The <strong>Cyber and Data Protection Regulations (S.I. 155 of 2024)</strong> set critical standards
                      for safeguarding personal data in Zimbabwe. Here is what businesses need to know:
                    </p>

                    <div className="mb-6">
                      <h4 className="font-semibold text-gray-800">Licensing Requirement</h4>
                      <ul className="list-disc pl-6 text-sm text-gray-700">
                        <li>All entities processing personal data commercially must obtain a Data Controllers License. Deadline for licensing is the 13th of March 2025.</li>
                        <li>Penalties for non-compliance: Fines (up to US$1,000) or imprisonment (up to 7 years).</li>
                      </ul>
                    </div>

                    <div className="mb-6">
                      <h4 className="font-semibold text-gray-800">Mandatory Appointment of Data Protection Officers (DPOs)</h4>
                      <ul className="list-disc pl-6 text-sm text-gray-700">
                        <li>Every licensed data controller must appoint a certified DPO.</li>
                        <li>DPO qualifications include expertise in law, data science, or security, with required certification.</li>
                        <li>Compliance includes regular professional development for DPOs and accredited training.</li>
                      </ul>
                    </div>

                    <div>
                      <h4 className="font-semibold text-gray-800">General Data Processing Rules</h4>
                      <ul className="list-disc pl-6 text-sm text-gray-700">
                        <li>Notify authorities about data processing activities and obtain consent for automated decisions.</li>
                        <li>Special rules apply for sensitive data, like biometrics and children’s information.</li>
                        <li>Report security breaches to the Authority (POTRAZ) within 24 hours and affected individuals within 72 hours.</li>
                      </ul>
                    </div>

                    <p className="text-sm text-gray-700 mt-4">
                      For more comprehensive information, be sure to seek professional legal advice. Stay ahead—ensure your business meets these obligations.
                    </p>
                  </div>

                  {/* Footer */}
                  <div className="bg-gray-100 text-center p-4 rounded-b-lg">
                    <p className="text-sm text-gray-600">
                      <strong>COGHLAN, WELSH & GUEST</strong> | <a href="https://www.cwg.co.zw" className="text-blue-500 hover:underline">www.cwg.co.zw</a>
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
