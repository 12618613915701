

import TheLaw from "./Stories/TheLaw";
import Delays from "./Stories/Delays";
import LabourAmmendment from "./Stories/LabourAmmendment";
import { cld } from "../lib/cloudinarySetup";

export const eventsData = [
  {
    id: "3",
    eventImg: cld.image("law_oaicxr").toURL(), // Replace with your Cloudinary image ID
    authorImg: cld.image("dc_v8e856").toURL(),
    authorName: "Dillon Chemhere",
    authorTitle: "Associate",
    coAuthorName: "",
    coAuthorTitle: "",
    coAuthorImg: "",
    date: "08 AUGUST 2023",
    title: "Labour Amendment Discussion: Removal of 3 Months Notice Period",
    details:
      "The new labour amendment repealed section 12(4a) of the Labour Act which allowed both employers and employees to terminate a permanent contract of employment or a contract for a period of two or more years upon giving three months’ notice.",
    storyParagraph1: <LabourAmmendment />,

    refesences: "",
  },
  {
    id: "1",
    eventImg: cld.image("HighCourt_pdouyg").toURL(),
    authorImg: cld.image("Mduduzi_aibwzv").toURL(),
    authorName: " Mduduzi A. Ruwitah ",
    authorTitle: "Associate",
    coAuthorName: "",
    coAuthorTitle: "",
    coAuthorImg: "",
    date: "24 JUNE 2023",
    title: "POSSIBLE DELAYS IN HIGH COURT PROCEEDINGS",
    details:
      "There may be delays in the set down of matters in the High Court in the last half of this year due to urgent litigation associated with elections.",
    storyParagraph1: <Delays />,

    refesences: "",
  },

  {
    id: "2",
    eventImg: cld.image("miningrestrictions_zt69dm").toURL(),
    authorImg: cld.image("dia_nkp1qi").toURL(),
    authorName: "William Diarra",
    authorTitle: "Associate",
    coAuthorName: "Wellington Magaya",
    coAuthorTitle: "Partner",
    coAuthorImg: cld.image("Wellington_fohjnq").toURL(),
    date: "11 MARCH 2023",
    title: "New Government Restrictions on the Exportation of Base Minerals",
    details:
      "On the 16 th of December 2022, the Base Minerals Export Control (Lithium Bearing Ores and  Unbeneficiated Lithium) Order, 2022 (S.I. 213 of 2022) was promulgated.",
    storyParagraph1: <TheLaw />,

    refesences:
      "EXPORT CONTROLS AND COMPETITIVENESS IN AFRICAN MINING AND MINERALS PROCESSING INDUSTRIES, 2017, accessed at https://www.oecd-ilibrary.org/docserver/1fddd828-en.pdf?expires=1678689609&amp;id=id&amp;accname=guest&amp;checksum=31133E7334D00E5643D7789856038FD8",
  },
];
