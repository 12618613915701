import {  LinkedinIcon, MailIcon, MapPinIcon } from "lucide-react";
import React from "react";
import Map from "./Map";

export default function Footer2() {
  return (
    <footer className="bg-gray-100 text-gray-600 py-12">
      <div className="container mx-auto px-12 md:px-4">
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Page Shortcuts */}
            <div>
            <h3 className="font-bold text-lg mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="/" className="hover:text-primary transition-colors">Home</a></li>
              <li><a href="/OurProfile" className="hover:text-primary transition-colors">Our Profile</a></li>
              <li><a href="/insights" className="hover:text-primary transition-colors">Services</a></li>
 
            </ul>
          </div>

            {/* Social Links */}
            <div>
            <h3 className="font-bold text-lg mb-4">Connect With Us</h3>
            <div className="flex space-x-4">
              <a href="mailto:email@cwg.co.zw" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors">
                <MailIcon className="h-6 w-6" />
                <span className="sr-only">Email</span>
              </a>
              <a href="https://www.linkedin.com/company/coghlan-welsh-and-guest/" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition-colors">
                <LinkedinIcon className="h-6 w-6" />
                <span className="sr-only">LinkedIn</span>
              </a>
            </div>
          </div>


            {/* Contact Information */}
            <div className="col-span-2  md:col-span-1">
            <h3 className="font-bold text-lg mb-4">Contact Us</h3>
            <ul className="space-y-2">
              <li className="flex items-center">
                <MapPinIcon className="h-10 w-10 mr-2" />
                <span>Cecil House 2 Central Avenue (corner Second Street)Harare, Zimbabwe</span>
              </li>
              <li className="flex items-center">
                <div>
  <p>Contact us:</p>
  <a href="tel:+263242794930" className="hover:text-primary transition-colors hover:underline mr-4">+263-242-794930</a>, 
  <a href="tel:+263242704933" className="hover:text-primary transition-colors hover:underline mr-4">+263-242-704933</a>, 
  <a href="tel:+263242704935" className="hover:text-primary transition-colors hover:underline mr-4">+263-242-704935</a>, 
  <a href="tel:+263712237347" className="hover:text-primary transition-colors hover:underline mr-4">+263-712-237347</a>, 
  <a href="tel:+263772161230" className="hover:text-primary transition-colors hover:underline mr-4">+263-772-161230</a>
</div>              </li>
              <li className="flex items-center">
                <MailIcon className="h-5 w-5 mr-2" />
                <a href="mailto:email@cwg.co.zw" className="hover:text-primary transition-colors">email@cwg.co.zw</a>
              </li>
            </ul>
          </div>

            {/* Map Section */}
        <div className="mt-8 col-span-2 md:col-span-1">
          <h3 className="font-bold text-lg mb-4">Find Us</h3>
          <div className="aspect-w-16 aspect-h-9">
          <Map/>
          </div>
        </div>


      </div>

      
          {/* Copyright */}
          <div className="mt-8 pt-8 border-t border-gray-400 text-center">
          <p>&copy; {new Date().getFullYear()} Coghlan, Welsh & Guest. All rights reserved.</p>
        </div>

          {/* Copyright */}
          <div className="mt-8 pt-8  text-sm text-center">
          <a
            href="mailto:lusenakalee@gmail.com;sharahwa@gmail.com"
            className="text-gray-600 hover:text-gray-900"
          >
            Designed by Shungu Harahwa & Leroy Lusenaka
          </a>
        </div>

      </div>
    </footer>
  );
}
